import { useRestApiProvider } from "@jugl-web/rest-api";
import { MultiSectionLayout } from "@jugl-web/ui-components";
import { WebTaskPageHeader } from "@web-guest-src/modules/tasks/pages/GuestTaskPage/components/WebTaskPageHeader";
import { FC } from "react";
import { useMatch } from "react-router-dom";
import { OrderSubmitForm } from "@jugl-web/domain-resources/orders/components/OrderSubmitForm";

export const GuestOrderFormPage: FC = () => {
  const match = useMatch("/order/:entityId/:formId");
  if (!match?.params.entityId || !match?.params.formId) {
    throw new Error("Invalid route");
  }
  const { ordersApi } = useRestApiProvider();
  const { entityId, formId } = match.params;
  const { data: initData } = ordersApi.useGuestInitQuery({ entityId });

  return (
    <MultiSectionLayout
      header={<WebTaskPageHeader title={initData?.entity?.name || ""} />}
      className="h-[100vh] bg-[#F4EEE8]"
    >
      <div className="mx-auto h-full w-full px-4 pt-[60px]">
        <div className="mx-auto flex w-full max-w-[720px] flex-col items-center rounded-xl pb-12 md:min-h-full md:rounded-t-xl">
          <OrderSubmitForm
            captchaMode="grc"
            entityId={entityId}
            formId={formId}
            isGuestApp
          />
        </div>
      </div>
    </MultiSectionLayout>
  );
};
