import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  DataLoadingWrapper,
  MultiSectionLayout,
} from "@jugl-web/ui-components";
import { FC, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import linkExpiredAnimation from "./assets/link-expired-animation.json";

export const GuestOrderFormShortLink: FC = () => {
  const match = useMatch("/F/:formShortId");
  const navigate = useNavigate();
  if (!match?.params.formShortId) {
    throw new Error("Invalid route");
  }
  const { formShortId } = match.params;
  const { linksApi } = useRestApiProvider();
  const { data, isLoading, isError } = linksApi.useGetFormShortLinkDataQuery({
    shortLinkId: formShortId,
  });

  useEffect(() => {
    if (!data?.module_res) {
      return;
    }
    navigate(`/order/${data.module_res.entity_id}/${data.module_res.id}`);
  }, [data, navigate]);

  return (
    <MultiSectionLayout className="h-[100vh] bg-[#F4EEE8]">
      <DataLoadingWrapper
        isLoading={isLoading}
        isError={isError}
        customErrorContentProps={{
          type: "custom",
          customTitle: "Form not found",
          customSubtitle: "This Form is missing or deleted",
          customImg: (
            <Lottie options={{ animationData: linkExpiredAnimation }} />
          ),
        }}
      />
    </MultiSectionLayout>
  );
};
