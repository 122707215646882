import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { TaskAttachmentItem } from "@jugl-web/domain-resources/tasks/components/TaskAttachments/components/TaskAttachmentItem";
import { DetailedTask, TaskAttachment } from "@jugl-web/rest-api/tasks";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import React from "react";
import {
  downloadBlobAsFile,
  useDownloadFile,
} from "@jugl-web/domain-resources/drive";
import { PageSection } from "../PageSection";
import { ReactComponent as AttachmentsIcon } from "./assets/attachments.svg";

export const TaskAttachmentsSection: React.FC<{
  task: DetailedTask;
}> = ({ task }) => {
  const { t } = useTranslations();
  const { previewFile } = useFilePreview();

  const { downloadFile } = useDownloadFile();
  const { variant } = useAppVariant();
  const { toast, closeToast } = useToast({ variant });

  const handleDownloadAttachment = async (attachment: TaskAttachment) => {
    const downloadingToastId = toast(
      t({ id: "feedback.file-downloading", defaultMessage: "Downloading..." }),
      { variant: "info", persist: true }
    );

    const response = await downloadFile({
      isGuestApp: true,
      entityId: task.entity_id,
      id: attachment.id,
      fileName: attachment.name,
      fileSize: attachment.size,
      mimeType: attachment.content_type,
    });

    if ("data" in response) {
      downloadBlobAsFile(response.data, attachment.name);
    } else {
      toast(
        t(
          {
            id: "feedback.attachment-download-failed",
            defaultMessage: "Couldn't download file {fileName}",
          },
          { fileName: attachment.name }
        ),
        { variant: "error", style: { zIndex: 99999 } }
      );
    }

    closeToast(downloadingToastId);
  };

  const handlePreviewAttachment = (attachment: TaskAttachment) => {
    previewFile({
      url: attachment.stream_url,
      name: attachment.name,
      mimeType: attachment.content_type,
      onDownload: () => handleDownloadAttachment(attachment),
    });
  };

  return (
    <PageSection
      title={t({
        id: "guest-task-page.attachments",
        defaultMessage: "Attachments",
      })}
      icon={<AttachmentsIcon />}
    >
      {!task.attachments.length ? (
        <span className="text-dark-500 text-xs">
          {t({
            id: "guest-task-page.no-attachments-yet",
            defaultMessage: "No Attachments yet",
          })}
        </span>
      ) : (
        <div className="flex flex-wrap items-center gap-4">
          {task.attachments.map((attachment) => (
            <TaskAttachmentItem
              key={attachment.id}
              attachment={attachment}
              isEditable
              layout="grid"
              onPreview={() => handlePreviewAttachment(attachment)}
              onDownload={() => handleDownloadAttachment(attachment)}
            />
          ))}
        </div>
      )}
    </PageSection>
  );
};
